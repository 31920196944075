import * as React from "react"
import PropTypes from "prop-types"
import "../styles/header.scss"

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Flex,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import { MdMenu } from "react-icons/md"

export default function Header({ siteTitle }) {
  Header.propTypes = {
    siteTitle: PropTypes.string,
  }

  Header.defaultProps = {
    siteTitle: ``,
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  return (
    <header className="header">
      <div className="desktopHeader">
        <Link to="/">
          <h1>Steen Lauth</h1>
        </Link>

        <nav>
          <Link activeClassName="activeLink" to="/projekter">
            Seneste projekter
          </Link>
          <Link activeClassName="activeLink" to="/om-at-bygge-hus">
            Om at bygge hus
          </Link>
          <Link activeClassName="activeLink" to="/om-mig">
            Om Steen Lauth
          </Link>
          <Link activeClassName="activeLink" to="/referencer">
            Referencer
          </Link>
          <Link activeClassName="activeLink" to="/kontakt">
            Kontakt
          </Link>
        </nav>
      </div>

      <div className="mobileHeader">
        <Link to="/">
          <h1>Steen Lauth</h1>
        </Link>

        <Button ref={btnRef} colorScheme="white" onClick={onOpen}>
          <MdMenu color="black" />
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <Flex
              flexDirection="column"
              marginTop="auto"
              marginRight="10px"
              fontSize="25px"
              textAlign="right"
              marginBottom="20px"
            >
              <Link activeClassName="activeLink" to="/projekter">
                Seneste projekter
              </Link>
              <Link activeClassName="activeLink" to="/om-at-bygge-hus">
                Om at bygge hus
              </Link>
              <Link activeClassName="activeLink" to="/om-mig">
                Om Steen Lauth
              </Link>
              <Link activeClassName="activeLink" to="/referencer">
                Referencer
              </Link>
              <Link activeClassName="activeLink" to="/kontakt">
                Kontakt
              </Link>
            </Flex>
          </DrawerContent>
        </Drawer>
      </div>
    </header>
  )
}
